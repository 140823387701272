import Vue from 'vue';
import Router from 'vue-router';
import Home from '../components/Home.vue';
import test1 from '../components/computer.vue';
import mouse_tracking from '../components/mouse_tracking.vue';

// 注册 Vue Router
Vue.use(Router);

// 导出路由配置
export default new Router({
    mode: 'history', // 使用历史模式，去掉 URL 中的 #
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/computer',
            name: 'computer',
            component: test1,
        },
        {
            path: '/mouse_tracking',
            name: 'mouse_tracking',
            component: mouse_tracking,
        },
    ],
});
