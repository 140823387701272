<template>
  <div class="outer-most">
    <div class="shell">
      <div class="item" v-for="(item, index) in items" :key="index"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mouse_tracking',
  data() {
    return {
      items: Array(225).fill(null)
    }
  },
  mounted() {
    document.addEventListener('mousemove', this.handleMouseMove);
  },
  destroyed() {
    document.removeEventListener('mousemove', this.handleMouseMove);
  },
  methods: {
    handleMouseMove(e) {
      const item = document.querySelectorAll(".item");
      const mouseX = e.clientX-20;
      const mouseY = e.clientY-20;
      item.forEach(sqr => {
        const sqrX = sqr.offsetLeft;
        const sqrY = sqr.offsetTop;
        const diffX = mouseX - sqrX;
        const diffY = mouseY - sqrY;
        const radians = Math.atan2(diffY, diffX);
        const angle = radians * 180 / Math.PI;
        sqr.style.transform = `rotate(${angle}deg)`;
      });
    }
  }
};
</script>

<style lang="less">
  *{
    margin: 0;
    padding: 0;
  }
  .outer-most {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(30, 30, 30);
  }
  .shell{
    display: grid;
    grid-template-columns: repeat(15,30px);
    grid-template-rows: repeat(15,30px);
    grid-gap: 1.5rem;
  }
  .item{
    background-color: #eed9eba9;
    clip-path: polygon(0 35%, 0 65%, 100% 50%);
  }
  .item::after,.item::before{
    content: '';
    width: 5px;
    height: 5px;
    display: block;
    position: absolute;
    border-radius: 50%;
    left: 20px;
    background-color: #fff;
  }
  .item::after{
    top: 25px;
  }
  .item::before{
    bottom: 25px;
  }
</style>
